import { FC } from 'react';
import { Box, BoxProps, Container } from '@chakra-ui/react';
import { EntityMapping } from '../../config/entity-mapping';
import { Paragraphs } from '../../types/global';

interface ParagraphRendererProps {
  paragraphs: Paragraphs;
  wrapperProps?: BoxProps;
  paragraphWrappers?: Record<string, string>;
  getWrapperForParagraph?: (paragraphName: string) => string;
}

const getWrapper = (name: string, Component: JSX.Element) => {
  if (name === 'container') {
    return <Container maxW="container.main">{Component}</Container>;
  } else if (name === 'containerSmall') {
    return <Container maxW="container.small">{Component}</Container>;
  }

  return Component;
};

export const ParagraphRenderer: FC<ParagraphRendererProps> = ({
  paragraphs,
  wrapperProps,
  paragraphWrappers,
  getWrapperForParagraph,
}) => {
  if (!paragraphs) return null;

  return (
    <Box className="paragraphs">
      {paragraphs.map((paragraph, i) => {
        const Component = EntityMapping[paragraph.__typename] || null;

        if (!Component) {
          console.warn(
            '[ParagraphRenderer] Paragraph not found',
            paragraph.__typename
          );
          return null;
        }

        const InnerComponent = <Component data={paragraph} />;

        let OuterComponent = InnerComponent;

        if (paragraphWrappers) {
          if (paragraphWrappers[paragraph.__typename]) {
            OuterComponent = getWrapper(
              paragraphWrappers[paragraph.__typename],
              InnerComponent
            );
          } else if (paragraphWrappers._all) {
            OuterComponent = getWrapper(paragraphWrappers._all, InnerComponent);
          }
        }

        if (getWrapperForParagraph) {
          const wrapperName = getWrapperForParagraph(paragraph.__typename);

          if (wrapperName) {
            OuterComponent = getWrapper(wrapperName, InnerComponent);
          }
        }

        if ('contentWidth' in paragraph) {
          if ((paragraph as any).contentWidth === 'small') {
            OuterComponent = getWrapper('containerSmall', InnerComponent);
          }
        }

        return (
          <Box
            py={3}
            className={paragraph.__typename}
            key={`${(paragraph as any).id}_${i}`}
            pos="relative"
            __css={{
              clear: 'both',
            }}
            {...wrapperProps}
          >
            {OuterComponent}
          </Box>
        );
      })}
    </Box>
  );
};
