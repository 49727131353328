import { FC } from 'react';
import { ParagraphRenderer } from '../ParagraphRenderer';
import {
  ParagraphFromLibraryFragment,
  ParagraphFromLibraryItemFragment,
} from '../../../generated/types';

interface FromLibraryProps {
  data: ParagraphFromLibraryFragment;
}

export const FromLibrary: FC<FromLibraryProps> = ({ data }) => {
  return <ParagraphRenderer paragraphs={data.paragraph.content} />;
};
